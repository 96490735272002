import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';

class ModalPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }


    render() {
        let block = ''
        let transaction = ''
        if (this.props.block !== null) {
            block = this.props.block
            transaction = block.transactions[0]
        }
        return (
            <Fragment>
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="sign-in-title">
                            <h1>Block Information</h1>
                            <b>Block Index: {block.index}</b>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container" style={{'wordWrap':'break-word'}}>
                            <div className="row">
                                <div className="col">
                                    <b>HASH</b>
                                </div>
                                <div className="col">
                                    {block.hash}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>PREVIUS HASH</b>
                                </div>
                                <div className="col">
                                    {block.prev_hash}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>TIMESTAMP</b>
                                </div>
                                <div className="col">
                                    {block.timestp}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>ENCRYPTED DATA</b>
                                </div>
                                <div className="col">
                                    {transaction.data}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>SENDER ID</b>
                                </div>
                                <div className="col">
                                    {transaction.issuer_id}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>RECEIVER ID</b>
                                </div>
                                <div className="col">
                                    {transaction.recipient_id}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal >
            </Fragment >

        );
    }
}

export default (ModalPopup); 