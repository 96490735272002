import React, { Component } from 'react';
import '../css/Home.css';
import { sendData } from './../utils/util'
class Blockchain extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedFile: null,
            load:false,
            error:'',
            
        }
    }
   

    onFileChange = event => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

    };
    onFileUpload = () => {
        if (this.state.selectedFile !== null) {
            this.setState({load:true})
            const formData = new FormData();
            formData.append(
                "myFile",
                this.state.selectedFile,
                this.state.selectedFile.name
            );


            sendData("https://api.naitsirc.industries/uploadFile", formData).then(response => {
                
                if(response.data !== 'error'){
                    sessionStorage.setItem('isLogged',1)
                    sessionStorage.setItem('account',response.data.account)
                    sessionStorage.setItem('private_key',response.data.keys.private_key)
                }
                else{
                    this.setState({error: 'Incorrect file selected'})
                }
                this.setState({load:false})
            });
        }

    };
    fileData = () => {

        if (this.state.selectedFile) {

            return (
                <div>
                    <h2>File Details:</h2>

                    <p style={{'wordWrap': 'break-word'}}>File Name: {this.state.selectedFile.name} </p>


                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };
    render() {
        const handleCopy = (e) => {
            let account = sessionStorage.getItem('account')
            navigator.clipboard.writeText(account)
            e.preventDefault()
          } 
        let isLogged = sessionStorage.getItem('isLogged')
        let content = <div className="plaque">
        <h1>Connect Account</h1>
        <div name="theForm">
            <div className="actions">
                <div>
                    <div className="image-upload">
                        <label htmlFor="file-input">
                            <img src="https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/128/Downloads-icon.png" style={{ cursor: 'pointer' }} alt='download' />
                        </label>

                        <input id="file-input" type="file" onChange={this.onFileChange} style={{ display: 'none' }} />
                    </div>
                    <button type="button" className="btn btn-primary" onClick={this.onFileUpload}>
                        Upload!
                    </button>
                </div>
                {this.fileData()}
                <b style={{'color':'red'}}>{this.state.error}</b>
            </div>
        </div>
    </div>
    if(this.state.load){
        content = <div className="spinner-border" role="status"> <span className="sr-only"></span></div>;
    }
        return (
            <div className="container mt-4" >
                {!Number(isLogged) ? (
                content
    ): ( 
    
    <div><div style={{'wordWrap': 'break-word'}}>You are already logged with account: {sessionStorage.getItem('account')}</div>
    <button className='btn-primary' onClick={(e) => handleCopy(e)} style={{'borderRadius':'30px'}}>Copy Account</button>
    </div>)}
            
            </div>
        );
    }
}

export default Blockchain;