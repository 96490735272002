import { Route, Switch } from 'react-router-dom';
import Home from '../components/Home';
import Hash from '../components/Hash';
import Blockchain from '../components/Blockchain'
import SendTransaction from '../components/SendTransaction';
const Routes = (props) => {
      return (
        
        <Switch>     
            <Route exact path="/" component={Home}/> 
            <Route path="/blockchain" component={() => <Home />}/>
            <Route path="/generatekey" component={() => <Hash />}/>
            <Route path="/connectaccount" component={()=><Blockchain/>}/>
            <Route path="/transaction" component={()=><SendTransaction/>}/>
        </Switch>
      );
    
  }
  
  export default Routes;
