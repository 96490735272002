import React, { Component } from 'react';
import { sendData } from './../utils/util'
class SendTransaction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            address: '',
            message:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        this.setState({address: event.target.value});
      }
    handleChange2(event) {
        this.setState({message: event.target.value});
      }
    
      handleSubmit(event) {
        let sender = sessionStorage.getItem('account')
        this.sendMessage(sender,this.state.address,this.state.message);
        this.setState({address: ''});
        this.setState({message: ''});
        event.preventDefault();
      }
    sendMessage = (sender,address,message) => {
        let data = {'sender':sender,'address':address,'message':message};
        sendData('https://api.naitsirc.industries/addTransaction',data)
    }
    render() {
        return (
            <div className="container mt-4" >
                <div className="plaque">
                    <h1>Send Encrypted Message</h1>
                    <div name="theForm">
                        <div className="actions">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row mb-2">
                                    <input type="text"  placeholder="Destination Account"  value={this.state.address} onChange={this.handleChange}/>
                                </div>
                                <div className="row mb-2">
                                        <input type="text"  placeholder="Message" value={this.state.message} onChange={this.handleChange2}/>
                                </div>
                                <div className="row">
                                    <input  className = "btn-primary " type="submit" value="Submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SendTransaction;