import React, { Component } from 'react';
import '../css/Home.css';
import {getData} from './../utils/util'
class Hash extends Component {

    constructor(props) {
        super(props)
        this.state = {
            load: false,
        }
    }

    onClick = () => {
        this.setState({load:true})
        getData('https://api.naitsirc.industries/generate_secure_key').then(response=>{
            const filename = response.data.filename
            const keys = response.data.keys
            
            const element = document.createElement("a");
            const file = new Blob([keys], {
                type: "text/plain"
              });
              element.href = URL.createObjectURL(file);
            element.download = filename;
            document.body.appendChild(element);
            element.click();
            this.setState({load:false})
        });
    }
    render() {
        let loading = <input type="button" value="Create" onClick={this.onClick} className="btn" />;
        if(this.state.load){
            loading = <div className="spinner-border" role="status"> <span className="sr-only"></span></div>;
        }
        return (
            <div className="container mt-4" >
                <div className="plaque">
                    <h1>Generate Secure Keys</h1>
                    <div name="theForm">
                        <div className="actions">
                            {loading}
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hash;