import React, { Component } from 'react';
import '../css/Home.css';
import { getData, sendData } from './../utils/util'
import ModalPopup from './ModalPopup';
import {FcInfo} from 'react-icons/fc'
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chain: [],
            showModalPopup: false,
            block : null

        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let chain = [];
        getData('https://api.naitsirc.industries/get_chain').then(response => {
            chain = response.data.chain
            this.setState({ chain });
        });

    }
    handleChange(event) {
        let actual_block = event.target.innerText
        this.showBlock(actual_block)
    }
    showBlock(index) {
        let data = [{ 'index': index }]
        let block = null
        sendData('https://api.naitsirc.industries/getSingleBlock', data).then(response => {
            block = response.data['block'];
            this.setState({block})
            this.isShowPopup(true)
        })
    }
    isShowPopup = (status) => {
        this.setState({ showModalPopup: status });
    }
    render() {

        return (
            <div className="container table-responsive py-5">
                <table className="table table-bordered table-hover" style={{ 'tableLayout': 'fixed' }}>
                    <thead className="table-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Issuer ID</th>
                            <th scope="col" style={{ overflow: 'hidden' }}>Recipient ID</th>
                        </tr>
                    </thead>
                    <tbody >
                        {this.state.chain.map(chain => (
                            <tr key={chain.index}>
                                <th scope="row" onClick={this.handleChange} style = {{'cursor':'pointer'}}>
                                    <div><b>{chain.index}</b></div>
                                    <FcInfo size={33} style = {{'zIndex':'-1','position':'relative'}}/>
                                </th>
                                <td style={{ 'wordWrap': 'break-word' }}>{String(chain.transactions[0].data).substring(0, 100)}</td>
                                <td style={{ 'wordWrap': 'break-word' }}>{chain.transactions[0].issuer_id}</td>
                                <td style={{ 'wordWrap': 'break-word' }}>{chain.transactions[0].recipient_id}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <ModalPopup
                    showModalPopup={this.state.showModalPopup}
                    onPopupClose={this.isShowPopup}
                    block = {this.state.block}
                ></ModalPopup>
                <FcInfo size={33}/> For Information Click On Block Number 
            </div>





        );
    }
}

export default Home;