import axios from 'axios';

export const getData = async (url) => {
    try {
      const request = await axios.get(
        url
      );
      return request;
    } catch (error) {
      console.log(error)
    }
  };

  export const sendData = async (url,data) => {
    try {
      const request = await axios.post(
        url,data
      );
      return request;
    } catch (error) {
      console.log(error)
    }
  };

