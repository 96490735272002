import React, { Component } from 'react'
import '../css/Navbar.css'
import { Navbar, Nav } from 'react-bootstrap';
import Notifications from 'react-notifications-menu';
import {sendData} from './../utils/util'
class Navigation extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      data: [],

    }
  }
  checkUserMessage(chain){
    let data = []
    data.push({
      image: '/images/cube.png',
      message: (<p key={0}>
        Genesis Block 
      </p>)
    })
    chain['chain'].forEach(element => {
      if(element.recipient_id === sessionStorage.getItem('account')){
        data.push({
          image: '/images/cube.png',
          message: (
            ` b${element.index} ->${element.issuer_id} sended:  ${element.message} `
            ),
          detailPage : '/blockchain',
        })
      }
    });
    this.setState({data})
  }
  componentDidMount() {
    this.checkBlockchain()    
}
  onClick = () => {
    this.checkBlockchain()
}

checkBlockchain(){
  let decrypt = sessionStorage.getItem('private_key')
    
    if(String(decrypt) !== 'null'){
      let data = {'decrypt':decrypt};
      sendData('https://api.naitsirc.industries/getNotification',data).then(response => {
          this.checkUserMessage(response.data);
      });
    }
}
  render() {
    const is_logged = sessionStorage.getItem('account')
    let navbar_account = 'CONNECT TO BLOCKCHAIN'
    if(is_logged){
      navbar_account = 'ACCOUNT'
    }
    return (

      <Navbar collapseOnSelect expand='lg' className="navbar d-flex flex-row-reverse">
        <Navbar.Toggle aria-controls="responsive-navbar-nav blue" style={{ backgroundColor: 'info', border: 1 }} />
        <Navbar.Brand className='ml-auto' onClick={this.onClick}>
          <Notifications  data={this.state.data} width='80%'  
            header={
              {
                title: 'Notifications',
                option: { text: 'Send Message', onClick: () => {window.location.href = '/transaction';},
               }
              }
              
        }/>
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" >
            <Nav.Link className="text-white" href='/blockchain'>BLOCKCHAIN</Nav.Link>
            <Nav.Link className="text-white" href='/generatekey'>GENERATE KEY</Nav.Link>
            <Nav.Link className="text-white" href='/connectaccount'>{navbar_account}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;